export default [
  {
    key: 'media_id',
    image: 'image_url',
    label: 'Ikon Kategori',
    type: 'image',
    required: true,
    notes: [
      'Gunakan gambar <b>rasio 1:1 (persegi)</b> dengan latar belakang transparan.',
      'Contoh: 480 x 480 px atau 256 x 256 px.',
      'Ikon dapat diunduh <b><a href="https://fonts.google.com/icons?icon.set=Material+Symbols&icon.style=Rounded&icon.size=24&icon.color=%23000000" target="_blank">sini</a></b>, atau sumber lain.',
    ],
  },
  {
    key: 'name',
    label: 'Nama',
    required: true,
  },
]
